<template>
  <div>
    <actions-reclamations :interface-chosen="interfaceChosen"
                @record-reclamation-returned="recordReclamationReturned"
                @fetch-data-ref="$emit('fetch-data-ref', $event)"
                :is-update.sync="isUpdate"
                :reclamation-for-edit="reclamationForEdit"
                :prospect-options="prospectOptions"
                :assureurs-options="assureursOptions"
                :projet-contrat-data="projetContratData"
                :commercial-options="commercialOptions"
                @refresh-reclamation="$emit('refresh-reclamation',reclamation.id)"
                @refresh-list-reclamations = "$emit('refresh-list-reclamations')"
              />
    <b-card class="mb-2">
      <b-card-text>
        <b-row>
          <b-col md="12" sm="12">
            <div class="d-flex bd-highlight mb-2">
              <div class="bd-highlight text-uppercase h3 font-weight-bold">
                <b>Motif : demarchage</b>
              </div>
              <div class="ml-auto bd-highlight">
                <div class="demo-inline-spacing">
                  <b-button  @click="$emit('edit-reclamation')" 
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-0"
                    v-b-toggle.sidebar-reclamation
                    v-if="can('Modify complaint')|| isPrincipal()"
                    
                  >
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle text-capitalize">Modifier</span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-0"
                    @click="hisotorie(reclamation.id)"
                    v-if="can('Archive complaint')|| isPrincipal()"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span class="align-middle text-capitalize">Historiser</span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)' || currentUser.courtier_user[0].isPrincipal === 1"
                      :aria-expanded="String(isEventHandlerSidebarActive)"
                      aria-controls="sidebar-add-new-event"
                      size="sm"
                    class="mt-0"

                      variant="primary"
                      @click="isEventHandlerSidebarActive = true"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                    <span class="align-middle text-capitalize">Ajouter un évènement</span>
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12">
            <b-row class="mb-4">
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Date de création :
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ reclamation.created_at }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Date de réclamation :
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ reclamation.date_reclamation }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Date d’accusé de réception :
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ reclamation.date_acc_recep }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Canal de réclamation :
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ reclamation.canal_reclamtion }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Déclarant : </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ reclamation.declarant }}
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Assureur : </span>
                    </th>
                    <td class="pb-50 text-uppercase">
                      {{ reclamation.assureur }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Service concerné: </span>
                    </th>
                    <td class="pb-50">{{ reclamation.service_concerne }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Prise en charge : </span>
                    </th>
                    <td class="pb-50">{{ reclamation.pris_en_charge.name }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Niveau de traitement <span
                      v-b-popover.hover.right.html="`
                          Pour plus d’information, rendez-vous sur la FAQ
                          <ul>
                            <li>Niveau 1 : gestion d’une réclamation par la relation client</li>
                            <li>Niveau 2 : gestion d’une réclamation par le service réclamation</li>
                            <li>Niveau 2S : traitement sensible</li>
                            <li>Niveau 3 : gestion d’une réclamation par le médiateur</li>
                          </ul>
                        `"
                      title="Niveau de traitement"
                      variant="outline-primary"
                    >
                      <feather-icon icon="InfoIcon" class="cursor-pointer" size="21" />
                    </span>  :
                      </span>
                      
                    </th>
                    <td class="pb-50">{{ reclamation.niveau }}</td>
                  </tr>
                </table>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6" class="details">
                <h3
                  class="
                    text-primary text-decoration-underline
                    font-weight-bolder
                    mb-1
                  "
                >
                  <u>Détails</u>
                </h3>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th width="25%" class="pb-50">
                      <span class="font-weight-bolder">Réclamant : </span>
                    </th>

                    <td v-if="reclamation.reclamation_client" class="pb-50 text-uppercase cursor-pointer" @click="showProspect(reclamation.reclamation_client.id,reclamation.reclamation_client.type)">
                       
                      <u>{{ reclamation.client }}</u>
                    </td>
                    <td v-else class="pb-50 text-uppercase">
                      
                     {{ reclamation.client }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Statut du réclamant :
                      </span>
                    </th>
                    <td class="pb-50">{{ reclamation.statut }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Référence : </span>
                    </th>
                    <td class="pb-50  cursor-pointer" v-if="reclamation.reclamation_reference != null"  @click="showProjetContrat(reclamation.reclamation_reference.id, reclamation.source, reclamation.type)">
                      <u>{{ reclamation.reference_type }}</u>
                    </td>
                     <td class="pb-50" v-else>
                        {{ reclamation.reference_type }}
                    </td>
              
                  </tr>

                  <!--  -->
                </table>
                <!--  -->
                <b-row>
                  <b-col cols="8" class="d-flex align-items-center">
                    <div
                      style=" min-height: 100px;flex: 2;padding: 5px;border: 1px solid #4d25bc; width: 100%;"
                    >
                      <p class="w-100" style="">
                        <strong>Commentaire : </strong
                        ><span v-html="reclamation.comment"></span>
                      </p>
                    </div>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                      v-b-modal.modal_commentaire
                    >
                      <feather-icon icon="Edit3Icon" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6" v-if="reclamation.issue" class="issue">
                <h3
                  class="
                    text-primary text-decoration-underline
                    font-weight-bolder
                    mb-1
                  "
                >
                  <u>Issue :</u>
                  <b-badge
                    style="font-size: 11.9px; margin-left: 7px"
                    variant="light-warning"
                    >{{ reclamation.issue }}</b-badge
                  >
                </h3>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th  class="pb-50">
                      <span class="font-weight-bolder">Date de clôture : </span>
                    </th>
                    <td class="pb-50 text-uppercase">
                      {{ reclamation.date_cloture }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Réponse apportée :
                      </span>
                    </th>
                    <td class="pb-50" v-html="reclamation.reponse_app"></td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Mesures correctives :
                      </span>
                    </th>
                    <td class="pb-50" v-html="reclamation.mesure_cor"></td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <!-- basic modal -->
      <b-modal
        id="modal_commentaire"
        hide-footer
        centered
        title="Modifier Le Commentaire"
        :show="showModalLoading"
      >
        <b-overlay :show="showModalLoading" no-wrap />
        <validation-observer ref="formUpdateCommentaire">
          <b-form class="p-2" @submit.prevent="updateCommentaire">
            <b-form-group label="Commentaire*" label-for="commentaire">
              <div class="commentaire">
                <validation-provider
                  #default="{ errors }"
                  name="commentaire"
                  rules="required"
                >
                  <quill-editor
                    v-model="comment_keep"
                    id="commentaire"
                    :options="editorOptions('commentaire')"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                    >{{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-form-group>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Modifier
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
    <calendar-event-handler v-model="isEventHandlerSidebarActive" :event="event" :clear-event-data="clearEventData" @add-event="addEvent" :prospect-selected="tiersData"  />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BRow,
  VBToggle,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormGroup,
  BForm,
  BOverlay,
  BModal,
  VBPopover,
} from "bootstrap-vue";
import FullCalendar from '@fullcalendar/vue'

import Ripple from "vue-ripple-directive";
import moment from "moment";
import { formatDate } from "@core/utils/filter";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { quillEditor } from "vue-quill-editor";
import ActionsReclamations from '../ActionsReclamations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from '../../../calendries/calendarStoreModule'
import CalendarEventHandler from '../../../calendries/calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from '../../../calendries/useCalendar'
export default {
  components: {
    FullCalendar,
    CalendarEventHandler,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    BForm,
    BOverlay,
    BModal,
    quillEditor,
    ActionsReclamations
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    'b-popover' : VBPopover,
  },
  props: {
    tiersData:{
      type:Object
    },
    reclamation: {
      type: Object,
      default: {
        assureur: null,
        assureur_id: null,
        canal_reclamtion: null,
        client: null,
        comment: null,
        created_at: null,
        date_acc_recep: null,
        date_de_cloture: null,
        date_de_réclamation: null,
        declarant: null,
        etat: null,
        id: null,
        issue: null,
        mesure_cor: null,
        motif: null,
        niveau: null,
        num_reclamation: null,
        reclamable_id: null,
        reclamable_type: null,
        reclamation_client: null,
        reponse_app: null,
        service_concerne: null,
        statut: null,
        reclamation_reference: null,
        reference_type:null
      },
      required: true,
    },
    reclamationForEdit: {
        type: Object,
        required:true
      },
    interfaceChosen: {
        type: String,
        default: "Listing",
        required: false
      },
      isUpdate: {
        type: Boolean,
        default: true,
        required: true
      },
      prospectOptions: {
        type: Array,
        default: [],
        required: true,
      },
      assureursOptions: {
        type: Array,
        default: [],
        required: true
      },
      projetContratData: {
        type: Array,
        default: [],
        required: false
      },
      commercialOptions: {
        type: Array,
        default: [],
        required: true
      },
  },
  data() {
    return {
      showModalLoading: false,
      reference:null,
      comment_keep: null,
      snowOption: {
        theme: "snow",
        placeholder: {
          commentaire: "Ecrivez votre commentaire…",
          reponse: "Ecrivez la réponse apportée…",
          mesure: "Ecrivez les mesures correctives…",
        },
        modules: {
          toolbar: ["bold", "italic", "underline", "strike"],
        },
      },
    };
  },
 
  mounted() {
    this.inialeCommentKeep();
    this.reference = this.reclamation.reclamation_reference;
  },
  methods: {
    showProjetContrat(id, type, inter) {
      if (inter == 'FicheContrat') {
         this.$router.push({
          name: 'contrats-list',
          params: {
            'id': id,
            'type': type,
            'interfaceContrat': inter,
          }
        })  
      }
      else {
        this.$router.push({
          name: 'projets-list',
          params: {
            'id': id,
            'type': type,
            'interfaceChosen': inter
          }
        })

      }
     
    },
    fetchDataRef(){
      this.$emit('fetch-data-ref');
    },
    recordReclamationReturned(){
      this.$emit('record-reclamation-returned');

    },
    inialeCommentKeep() {
      this.comment_keep = this.reclamation.comment;
    },
    editorOptions(fieldName) {
      return {
        theme: this.snowOption.theme,
        placeholder: this.snowOption.placeholder[fieldName],
        modules: this.snowOption.modules,
      };
    },
    updateCommentaire() {
      this.$refs.formUpdateCommentaire.validate().then((success) => {
        if (success) {
          this.showModalLoading = true;
          this.$http
            .post("reclamations/editCommentReclamation", {
              reclamationId: this.reclamation.id,
              comment: this.comment_keep,
            })
            .then((response) => {
              console.log(response.data.data.comment);
              if (response.data.success) {
                this.reclamation.comment = response.data.data.comment;
                this.inialeCommentKeep();
                this.messageToast(response.data.message, "Succès", "success");
              } else {
                this.messageToast(response.data.message, "Erreur", "danger");
              }
              this.showModalLoading = false;
              this.$bvModal.hide("modal_commentaire");
            })
            .catch((err) => {
              this.messageToast(err.message, "Erreur", "danger");
              this.showModalLoading = false;
            });
        }
      });
    },
    showProspect(id, type) {
      if (type == "PERSONNE_PHYSIQUE") {
        this.$router.push({
          name: "tiers-list",
          params: { id: id, type: type, interfaceChosen: "Professionnel" },
        });
      } else {
        this.$router.push({
          name: "tiers-list",
          params: {
            id: id,
            type: "PERSONNE_MORALE",
            interfaceChosen: "Entreprise",
          },
        });
      }
    },
    hisotorie(id){
        this.$swal({
        title: 'Etes-vous sûr de vouloir historiser cette reclamation ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(result => {
              if (result.value) {
                this.showLoading = true
                this.$http
                  .post('/reclamations/updateReclamtionHisotorie', {
                    id: id,
                   })
                  .then(res => {
                    if (res.data.success) {
                      this.showLoading = false
                     this.$emit('refresh-reclamation',id)
                     this.$emit('refresh-list-reclamations')
                     this.$emit('back-to-listing');
                      this.messageToast('La réclamation a été historisée', 'Succès', 'success')
                    
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    console.error(err)
                  })
          
                }
              })
      },
  },
  setup() {
     const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      event,
      clearEventData,
      addEvent,
      calendarOptions,
      // ----- UI ----- //
      isEventHandlerSidebarActive
    } = useCalendar()

    return {
      event,
      clearEventData,
      addEvent,
      calendarOptions,
      // ----- UI ----- //
      isEventHandlerSidebarActive
    }
  }
};
</script>
<style>
.button-Commentaire {
  right: -37px;
  top: 0;
  height: 24px;
  width: 24px;
  padding: 3px !important;
}
.issue p{
    margin: 0 !important;
}
.details p{
  margin: 0 !important;
}
</style>