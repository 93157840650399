<template>
  <div>
    <b-overlay :show="showLoading" no-wrap/>
    <b-row>
      <b-col md="6" sm="12">
        <span class="h1 text-primary font-weight-bolder ml-1 text-uppercase"> 
          Fiche réclamation -   
          <span>{{ reclamation[0].num_reclamation }} </span>
        </span>
        <b-badge variant="light-warning">{{ reclamation[0].etat }}</b-badge>
      </b-col>
      <b-col md="6" sm="12">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="text-uppercase float-md-right"
            size="md"
            variant="primary"
            @click="backToListing"
            id="revenir-liste-projets"
        >
          <feather-icon icon="ArrowLeftIcon"/>
          <span class="align-middle"> revenir à la liste</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12" sm="12">
        <card-information :reclamation="reclamation[0]" :interface-chosen="interfaceReclamation"
                @record-reclamation-returned="recordReclamationReturned"
                @fetch-data-ref="fetchDataRef"
                :is-update.sync="isUpdate"
                @edit-reclamation="$emit('edit-reclamation', reclamation[0])"
                :reclamation-for-edit="reclamationForEdit"
                @back-to-listing="backToListing"
                :prospect-options="prospectData"
                :assureurs-options="assureursOptions"
                :projet-contrat-data="projetContratData"
                :commercial-options="commercialOptions"
                :tiers-data="tiersData"
                @refresh-reclamation="fetchReclamationById"
                @refresh-list-reclamations="$emit('refresh-list-reclamations')"
                />
      </b-col>
      <b-col md="12" sm="12">
        <b-card class="mb-2">
          <TabsDetailsFicheReclamation :reclamation="reclamation[0]"  />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCardText, BCol, BOverlay, BRow,VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CardInformation from '@/views/portefeuilles/reclamations/detail_fiche_reclamation/CardInformation.vue'
import TabsDetailsFicheReclamation from '@/views/portefeuilles/reclamations/detail_fiche_reclamation/TabsDetailsFicheReclamation.vue'

export default {
  components: {
    // UI
    // BTV
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    VBToggle,
    //Ui
    CardInformation,
    TabsDetailsFicheReclamation
  },
  directives: { Ripple },
  props: {
    reclamationChosen: {
      type: Object,
      default: {
        id: null,
        type: null
      },
      required: true,
    },
    reclamationForEdit: {
      type: Object
    },
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      showLoading: false,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      reclamation: [],
      interfaceReclamation: 'FicheReclamation',
      prospectData: [],
      assureursOptions: [{
        value: null,
        text: '-- Choisissez --'
      }],
      projetContratData: [],
      prospectChosen: {
        id: null,
        type: null
      },
      commercialOptions: [],
      isUpdate: true,
      tiersData: {}
    }
  },
  created(){
    this.fetchReclamationById(this.reclamationChosen.id)
    this.fetchAssureurList()
    this.fetchDataCommercialByCourtier()
    this.fetchProspectData()
 
   
  },
  mounted() {

 
  },
  watch: {
    reclamation: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val[0] && val[0].reclamation_client) {
          this.showLoading = true
          Promise.all([this.fetchDataTiers(val[0].reclamation_client.id, val[0].reclamation_client.type)]).then(() => {
            this.showLoading = false
          }).catch(()=>{
            this.showLoading = false
          });
        }
      }
    }
  },
  methods: {
    backToListing() {
      this.$emit('back-to-listing', 'Listing')
    },
    fetchReclamationById(id) {
      this.reclamation = []
      this.$http.post('reclamations/fetchReclamationById',{id : id}).then((res)=>{
        this.reclamation = res.data.data;
        this.projetContratData = []
        this.fetchDevis(this.reclamation[0])
        this.fetchContrats(this.reclamation[0])
      })    
  },
  fetchProspectData() {
      this.$http.get('/prospect/getAllProspect')
        .then(r => {
          this.pushAndFusiondata(r.data[0].concat(r.data[1]))
        })
        .catch(err=>{
          this.showLoading = false
          console.log(err)
        })
    },
    pushAndFusiondata(array) {
      this.prospect_filtred = []
      array.forEach(item => {
        
        this.prospect_filtred.push({
          id: item.type + '_' + item.id,
          idClient: item.id,
          client: item.type === 'PERSONNE_MORALE' ? item.denomination_commercial : item.nom + ' ' + item.prenom,
          nom: null,
          prenom: null,
          categorie: item.categorie,
          type: item.type
        })
      })
      this.prospectData = this.$_.orderBy(this.prospect_filtred, ['type'], ['desc'])
    },
    fetchAssureurList() {
       this.assureursOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
          .post('/assureurs/fetchAssureurList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.assureursOptions.push({
                  value: el.id,
                  text: el.nom_com_assureur,
                })
              })
            }
          })
          .catch(err => {
              this.assureursOptions = [{
                value: null,
                text: '-- Choisissez --'
              }]
            console.error(err)
          })
    },
    fetchContratsList() {
      this.$http
          .post('/contrat/fetchDataContrats', {
            courtier_user_id: this.currentUser.courtier_user[0].id,
            user_id: this.currentUser.courtier_user[0].user_id,
            courtier_id: this.currentUser.courtier_user[0].courtier_id,
            clientId: this.prospectChosen.idClient,
            clientType:this.prospectChosen.type
          })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(element => {
                this.projetContratData.push({
                  id: element.id,
                  numero: element.police_no ? element.police_no : 'NC',
                  source: element.source,
                  type: 'CONTRAT',
                  ref_externe: null
                })
              })
            }
          })
          .catch(err => {
            console.error(err)
          })
    },
    fetchContrats(reclamation) {
      console.log(reclamation)
      this.$http
        .post('/contrat/fetchDataContrats', {
          courtier_user_id: this.currentUser.courtier_user[0].id,
          user_id: this.currentUser.courtier_user[0].user_id,
          courtier_id: this.currentUser.courtier_user[0].courtier_id,
          clientId: reclamation.reclamation_client.id,
          clientType: reclamation.reclamation_client.type
        })
        .then(res => {
          if (res.data.success) {
            res.data.data.forEach(element => {
              this.projetContratData.push({
                id: element.id,
                numero: element.police_no ? element.police_no : 'NC',
                source: element.source,
                type: 'CONTRAT',
                ref_externe: null
              })
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    fetchDevisList() {
      this.$http
        .get(
          `/devis/fetchListDevisByTier/${this.prospectChosen.idClient}/${this.prospectChosen.type}`
        )
        .then((res) => {
          if (res.data.success) {
            res.data.data.forEach((element) => {
              this.projetContratData.push({
                id: element.id,
                numero: element.devis_no_externe
                  ? element.devis_no_externe
                  : element.devis_no_interne,
                source: element.etude.source,
                type: "PROJET",
                ref_externe: null,
              });
            });
          }
        })
        .catch((err) => {
          this.showLoading = false;
          this.prospectDevisLocalKeeper = [];
          this.prospectDevisLocal = [];
          console.error(err);
        });
    },
    fetchDevis(reclamation) {
      console.log(reclamation)
      this.$http
        .get(
          `/devis/fetchListDevisByTier/${reclamation.reclamation_client.id}/${reclamation.reclamation_client.type}`
        )
        .then((res) => {
          if (res.data.success) {
            res.data.data.forEach((element) => {
              this.projetContratData.push({
                id: element.id,
                numero: element.devis_no_externe
                  ? element.devis_no_externe
                  : element.devis_no_interne,
                source: element.etude.source,
                type: "PROJET",
                ref_externe: null,
              });
            });
            console.log(this.projetContratData)
          }
        })
        .catch((err) => {
          this.showLoading = false;
          this.prospectDevisLocalKeeper = [];
          this.prospectDevisLocal = [];
          console.error(err);
        });
    }, 
    fetchDataRef(prospect) {
      console.log(prospect)
      this.projetContratData = []
      this.prospectChosen.id = prospect.id;
      this.prospectChosen.idClient = prospect.idClient;
      this.prospectChosen.type = prospect.type
      this.fetchContratsList()
      this.fetchDevisList()
    },
    fetchDataCommercialByCourtier() {
        this.commercialOptions = []
        this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },
    recordReclamationReturned(reclamation, message) {
        // this.fetchreclamation()
        this.messageToast(message, 'Succès', 'success')
    },
    fetchDataTiers(id, type) {
      this.showLoading = true
      let url = '';
       if(type == 'PERSONNE_PHYSIQUE'){
         url = `/prospect/fetchDataTiersPro/${id}/${type}`
        }else{
         url = `/prospect/fetchDataTiers/${id}/${type}`
       }
      this.$http.get(url)
          .then(res => { 
            if (res.data.success) {
              this.tiersData = res.data.data
              this.showLoading = false
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
            console.log(err)
          })
    },
     
  }
}
</script>
