<template>
  <div>
    <!-- search input -->
    <div class="d-flex justify-content-end mb-1" >
      <b-button variant="primary" @click="joindre()">
        <feather-icon class="mr-50" icon="PlusIcon" />
        <span class="align-middle">Ajouter un document</span>
      </b-button>
      <ActionsDocument @fetch-document="fetchdocumentreclamation" :reclamation = "reclamation"  />

    </div>


    <!-- table -->
    <b-overlay :show="showLoadingo" no-wrap />
    <vue-good-table
        v-if="can('List other documents')"
      :columns="columnsAutresDocuments"
      :rows="rowsAutresDocuments"
      :rtl="direction"
      style-class="vgt-table condensed"
      :pagination-options="{
       enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
    >
     <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field === 'created_at'">
          {{ convertToFrenchDate(props.row.created_at) }}

        </span>
      
        <span v-else-if="props.column.field === 'actions'">     
          <span>
            <b-dropdown
              dropright
              no-caret
              toggle-class="text-decoration-none p-0"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle mr-25"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item  @click="displayDocument(props.row.id, props.row.nom, props.row.nom_custom, props.row.ext)"
              
              >
                <feather-icon class="mr-50" icon="SearchIcon" />
                <span>Visualiser</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteDocumentProjet(props.row.id)">
                <feather-icon class="mr-50" icon="TrashIcon" />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
          
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
            <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>

    <b-modal
        id="modal-display-document"
        ref="modal-display-document"
        :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'"
        :title="document.name"
        cancel-title="Fermer"
        cancel-variant="outline-secondary"
        ok-title="Télécharger"
        @ok="downloadDocument(document.base64, document.nameToDownload)"
    >
      <form ref="form" :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
        <b-img
            v-if="document.extensionDocument === 'IMAGE'"
            :alt="document.name"
            :src="document.base64"
            fluid
        />
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
      </form>
    </b-modal>

  </div>
</template>

<script>
import { BPagination, BFormGroup, BFormSelect, BFormSelectOption,BDropdown, BDropdownItem, BRow, BCol, BButton, BInputGroup, BInputGroupAppend, BOverlay, BModal, BImg, VBToggle } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import ActionsDocument from "./../ActionDocument";
import moment from "moment";


import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BModal,
    BImg,
    ActionsDocument,
    moment,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // UI
    // ActionsDocumentVue
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      showLoadingo: false,
      pageLength: 10,
      dir: false,
      contrat_id_doc:null,
      options: [
        { value: null, text: 'Action de masse' },
        { value: 'autre', text: ' --- ' }
      ],
      selected: null,
      columnsAutresDocuments: [
        { 
          label: 'id',
          field: 'id',
          sortable: false,
          hidden: true,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
        },
        { 
          label: 'Nom de document',
          field: 'nom_custom',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: "Date de l'ajout",
          field: 'created_at',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false, 
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6' 
        }
      ],
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
      rowsAutresDocuments: [],
      rowsAutresDocumentsKeep: [],
      typeSelected: null,
    }
  },
  props: {
    reclamation: {
      type: Object,
      default: {
        assureur: null,
        assureur_id: null,
        canal_reclamtion: null,
        client: null,
        comment: null,
        created_at: null,
        date_acc_recep: null,
        date_de_cloture: null,
        date_de_réclamation: null,
        declarant: null,
        etat: null,
        id: null,
        issue: null,
        mesure_cor: null,
        motif: null,
        niveau: null,
        num_reclamation: null,
        reclamable_id: null,
        reclamable_type: null,
        reclamation_client: null,
        reponse_app: null,
        service_concerne: null,
        statut: null,
      },
      required: true,
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  created() {
    this.fetchdocumentreclamation()
  },
  methods: {
    joindre(){
      console.log('1')
      this.$root.$emit('bv::toggle::collapse', 'sidebar-importNewReclamtion')
    },
    convertToFrenchDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument,ExtDuDocument) {
      var nomDocToDownload = ""
      if(NomDuDocument && ExtDuDocument) {
        nomDocToDownload = NomDuDocument + '.' + ExtDuDocument
      } else {
        nomDocToDownload = name
      }
      
      this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then(res => {
            if (res.data.success) {
              this.clearDocumentDisplay()
              if (res.data.data.isDownload) {
                this.downloadDocument(res.data.data.base64, nomDocToDownload)
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = nomDocToDownload
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = nomDocToDownload
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              }
            } else {
              this.clearDocumentDisplay()
              this.messageToast(res.data.message, 'Erreur', "danger")
            }
          })
          .catch(err => {
            this.clearDocumentDisplay()
            this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
            console.error(err)
          })
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    deleteDocumentProjet(id) {
      this.$swal({
        title: "Etes-vous sûr de vouloir supprimer ce document ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("document/deleteDocumentClient", { document_id: id })
            .then((res) => {
              for (let i = 0; i < this.rowsAutresDocuments.length; i++) {
                if (this.rowsAutresDocuments[i].id == id) {
                  this.rowsAutresDocuments.splice(i, 1);
                  break;
                }
              }
              this.fetchdocumentreclamation()

              // this.countdocument()
              this.messageToast(res.data.message, "Succès", "success");
            })
            .catch((error) => {
              console.log(error);
              this.messageToast(error.response.data, "Erreur", "error");
            });
        }
      });
    },
    fetchdocumentreclamation(){
      this.showLoading = true
      this.rowsAutresDocuments = []
      this.$http.get(`/document/fetchDocumentReclamation/${this.reclamation.id}`)
          .then(res => {
            if (res.data.success) {
              this.rowsAutresDocuments = res.data.data
              this.$emit('counting-document-reclamation', this.rowsAutresDocuments.length)
            }
          })
          .catch(err => {
            this.rowsAutresDocuments = []
            console.error(err)
          })


    },

 
  
   
    // deleteDocument(id, vgtId) {
    //   this.$swal({
    //     title: 'Etes-vous sûr de vouloir supprimer le document ?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Oui',
    //     cancelButtonText: 'Non',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1'
    //     },
    //     buttonsStyling: false
    //   }).then(confirmed => {
    //     if (confirmed.value) {
    //       this.$http
    //         .delete(`/document/delete_document_courtier/${id}`)
    //         .then(res => {
    //           if (res.data.success) {
    //             const indexDocument = this.rowsAutresDocuments.findIndex(item => item.id === id)
    //             this.rowsAutresDocuments.splice(indexDocument, 1)
    //             this.messageToast(res.data.message, 'Succès', 'success')
    //           } else {
    //             this.messageToast(res.data.message, 'Erreur', "danger")
    //           }
    //         })
    //         .catch(error => {
    //           console.log(error)
    //           this.messageToast(error.response.data, 'Erreur', "danger")
    //         })
    //     }
    //   })
    // },
   
  }
}
</script>
